// window.$ = window.jQuery = require('jquery/dist/jquery.min');
require('bootstrap/dist/js/bootstrap.min');
// require('popper.js/dist/popper.min');

// Photoswipe
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
const lightbox = new PhotoSwipeLightbox({
    gallery: '#gallery--getting-started',
    children: 'a',
    pswpModule: () => import('photoswipe')
});
lightbox.init();


import "inputmask/dist/inputmask.min";

let cnpjCpf = document.getElementById('cnpjCpf');
if(cnpjCpf) Inputmask().mask(cnpjCpf);

let fonFor = document.getElementById('fonFor');
if(fonFor) Inputmask().mask(fonFor);

// ------------------------------------------------Password View----------------------------------------------------

if ($('#show_hide_password').length > 0) {
    $("#show_hide_password").on('click', function(event) {
        event.preventDefault();
        if($('#password').attr("type") == "text"){
            $('#password').attr('type', 'password');
            $('#password-confirm').attr('type', 'password');
            $('#show_hide_password i').removeClass( "fa-eye" );
            $('#show_hide_password i').addClass( "fa-eye-slash" );
        }else if($('#password').attr("type") == "password"){
            $('#password').attr('type', 'text');
            $('#password-confirm').attr('type', 'text');
            $('#show_hide_password i').removeClass( "fa-eye-slash" );
            $('#show_hide_password i').addClass( "fa-eye" );
        }
    });


}

// ------------------------------main----------------------------------------------



if ($('#liveToast').length > 0) {
    $('#liveToast').toast('show');
}
if ($('#alertToast').length > 0) {
    $('#alertToast').toast('show');
}

$(document).ready(function () {
    window._token = $('meta[name="csrf-token"]').attr('content')

    moment.updateLocale('en', {
        week: {dow: 1} // Monday is the first day of the week
    })

    if ($('.date').length > 0) {
        $('.date').datetimepicker({
            format: 'DD-MM-YYYY',
            locale: 'en',
            icons: {
                up: 'fas fa-chevron-up',
                down: 'fas fa-chevron-down',
                previous: 'fas fa-chevron-left',
                next: 'fas fa-chevron-right'
            }
        })
    }

    if ($('.datetime').length > 0) {
        $('.datetime').datetimepicker({
            format: 'DD-MM-YYYY HH:mm:ss',
            locale: 'en',
            sideBySide: true,
            icons: {
                up: 'fas fa-chevron-up',
                down: 'fas fa-chevron-down',
                previous: 'fas fa-chevron-left',
                next: 'fas fa-chevron-right'
            }
        })
    }

    if ($('.datetime').length > 0) {
        $('.timepicker').datetimepicker({
            format: 'HH:mm:ss',
            icons: {
                up: 'fas fa-chevron-up',
                down: 'fas fa-chevron-down',
                previous: 'fas fa-chevron-left',
                next: 'fas fa-chevron-right'
            }
        })
    }
    if ($('.select-all').length > 0) {
        $('.select-all').click(function () {
            let $select2 = $(this).parent().siblings('.select2')
            $select2.find('option').prop('selected', 'selected')
            $select2.trigger('change')
        })

        $('.deselect-all').click(function () {
            let $select2 = $(this).parent().siblings('.select2')
            $select2.find('option').prop('selected', '')
            $select2.trigger('change')
        })

        $('.select2').select2()

    }

    if ($('.treeview').length > 0) {

        $('.treeview').each(function () {
            var shouldExpand = false
            $(this).find('li').each(function () {
                if ($(this).hasClass('active')) {
                    shouldExpand = true
                }
            })
            if (shouldExpand) {
                $(this).addClass('active')
            }
        })
    }


    $('.c-header-toggler.mfs-3.d-md-down-none').click(function (e) {
        $('#sidebar').toggleClass('c-sidebar-lg-show');

        setTimeout(function () {
            $($.fn.dataTable.tables(true)).DataTable().columns.adjust();
        }, 400);
    });

})


// -----------------------------------ENVIO DA COTACAO--------------------------------------------------


$(document).ready(function () {
    $(".navbar-nav li").removeClass("active");
    $("#cotacoes").addClass("active");

    if($("#grpqtd span").text() == "PC"){
        // $("#qtdcot").mask('999999999');
    }

    $("#ciffob").change(function(){
        // alert($(this).val());
        if($(this).val() == 'F')
        {
            $('#divfrecot').show();
            $('#somfre').val('S');
        }
        else
        {
            $('#divfrecot').hide();
            $('#divfrecot').val(0);
            $('#somfre').val('N');
        }

    }).trigger("change");

    $("#frecot").change(function(){

        var vFreCot = $(this).val();

        $(this).val(vFreCot);

    }).trigger("change");

    $("#ipicot").change(function(){

        var vIpiCot = $(this).val();

        if (vIpiCot > 15){
            alert( "Percentual acima da margem!" );
            $(this).val(0);
        }
        else{
            $(this).val(vIpiCot);
        }

    }).trigger("change");

    $("#icmcot").change(function(){

        var vIcmCot = $(this).val();

        if (vIcmCot > 22){
            alert( "Percentual acima da margem!" );
            $(this).val(0);
        }
        else{
            $(this).val(vIcmCot);
        }


    }).trigger("change");

    $("#precot").change(function(){

        var vPreCot = $(this).val()

        $(this).val(vPreCot);

    }).trigger("change");

    $('#precot,#ipicot,#icmcot').focusout(function(){

        //remove todos os pontos
        let nipicot = $("#ipicot").val().replace('.','');
        let nvlrcot = $("#precot").val().replace('.','');
        let nqtdcot = $("#qtdcot").val().replace('.','');
        nvlrcot = nvlrcot.replace('.','');

        // remove substitui virgula por ponto
        nipicot = nipicot.replace(',','.');
        nvlrcot = nvlrcot.replace(',','.');
        nqtdcot = nqtdcot.replace(',','.');

        // faz o calculo do "Valor Total"
        nvlrcot = nvlrcot * nqtdcot;
        nvlrcot = nvlrcot + (nvlrcot * (nipicot / 100));

        //converte para formato BR de dinheiro
        nvlrcot = nvlrcot.toLocaleString('pt-br', {minimumFractionDigits: 2});

        $("#vlrcot").val(nvlrcot);

    });

    //Press Enter in INPUT moves cursor to next INPUT
    $('#formcot').find('.input').keypress(function(e){
        if ( e.which == 13 ) // Enter key = keycode 13
        {
            $(this).next().focus();  //Use whatever selector necessary to focus the 'next' input
            return false;
        }
    });
});


// -------------------------------------------------------- campos numero com 2 casa depois da virgula -------------------------------
String.prototype.reverse = function(){
    return this.split('').reverse().join('');
};

function mascaraMoeda(campo,evento){
    var tecla = (!evento) ? window.event.keyCode : evento.which;
    var valor  =  campo.value.replace(/[^\d]+/gi,'').reverse();
    var resultado  = "";
    var mascara = "##.###.###,##".reverse();
    for (var x=0, y=0; x<mascara.length && y<valor.length;) {
        if (mascara.charAt(x) != '#') {
            resultado += mascara.charAt(x);
            x++;
        } else {
            resultado += valor.charAt(y);
            y++;
            x++;
        }
    }
    campo.value = resultado.reverse();
}


// -------------

// ------------------------------------------------Cep banco de talentos ----------------------------------------------------
function clear_cep() {
    $("#endereco").val("");
    $("#bairro").val("");
    $("#cidade").val("");
    $("#estado").val("");
}

$("#cepfor").blur(function() {
    let cep = $(this).val().replace(/\D/g, '');

    if (cep != "") {
        let validatecep = /^[0-9]{8}$/;

        if(validatecep.test(cep)) {

            $("#endfor").val("...");
            $("#baifor").val("...");
            $("#cidfor").val("...");
            $("#sigufs").val("...");
            $.getJSON("https://viacep.com.br/ws/"+ cep +"/json/?callback=?", function(ret) {

                if (!("erro" in ret)) {
                    $("#endfor").val(ret.logradouro);
                    $("#baifor").val(ret.bairro);
                    $("#cidfor").val(ret.localidade);
                    $("#sigufs").val(ret.uf);
                    // $("#cepfor").focus();

                }else {
                    clear_cep();
                    $("#cepfor").focus()
                    alert("CEP não encontrado.");
                    $("#cepfor").val("");
                }
            });
        }else {
            clear_cep();
            $("#cepfor").focus();
            alert("Formato de CEP inválido.");
            $("#cepfor").val("");
        }
    }else {
        clear_cep();
        $("#cepfor").focus()
    }
});



